/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.VUE_APP_NODE_ENV === 'development' || process.env.VUE_APP_NODE_ENV === 'aws-development') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    registrationOptions: {
      scope: process.env.BASE_URL,
    },
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated (registration) {
      console.log('New content is available; please refresh.')
      //if (confirm("New update is available. Do you want to refresh the page?")) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" });
      window.location.reload(true)
      //}
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}

self.addEventListener('message', (event) => {
  if (event.data && event.data.type === 'SKIP_WAITING') {
    self.skipWaiting();
  }
});